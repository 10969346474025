import values from 'lodash/values';
import UserModel from '../UserModel';
import { createMainLayout } from '../utils/helpers';
import app from '../config';

const getPreparedItems = (items, vendorId) =>
    values(items)
        .map((item) => (typeof item === 'function' ? item(vendorId) : item))
        .sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB);

const mainLayoutProps = (vendorId) => () => ({
    headerMenuItems: getPreparedItems(app.vendorHeaderMenuItems.getAll(), vendorId),
    sideMenuItems: getPreparedItems(app.vendorSideMenuItems.getAll(), vendorId),
    isSidebarWithSubPath: true
});

export default () => createMainLayout(mainLayoutProps(UserModel.getVendorId()));
